import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import agata_logo from '../images/agata_logo.svg';
import clochee_logo from '../images/clochee_logo.svg';
import filozofiaStopy_logo from '../images/filozofia-stopy_logo.svg';
import klinika_fizjoterapii from '../images/klinika_fizjoterapii.svg';
import usmiechZdrowia_logo from '../images/usmiech-zdrowia_logo.svg';
import dragonfly_logo from '../images/dragonfly_logo.svg';
import ayako_logo from '../images/ayako_logo.svg';
import Seo from '../components/seo';

const AboutPage = () => {
    return (
        <Layout>
            <Seo title="O Nas"/>
            <section className='about-container'>
                <section className='about-landing'>
                    <StaticImage 
                        src='../images/about_main.jpg'
                        alt="szerokie zdjęcie oświetlonego studia"
                        loading="eager"
                        placeholder="blurred"
                        className="about-landing_picture"
                    />
                    <h1 className='about-landing_title'>NASZE STUDIO</h1>
                </section>
                <section className='about-hero'>
                    <div className='about-hero-paragraph'>
                        <div className='about-hero-paragraph_name'>
                            <h2 className='about-hero-paragraph_title'>CZYM JEST PILATES TERAPEUTYCZNY?</h2>
                        </div>
                        <div className='about-hero-paragraph_content'>
                            <h3 className='about-hero-paragraph_bold-line'>Metoda Pilates to jeden z najbardziej rozbudowanych systemów ćwiczeń ruchowych pozwalający na optymalizację funkcji poszczególnych grup mięśniowych.</h3>
                            <p className='about-hero-paragraph_text'>Celem Pilatesu jest powrót do poprawnej postawy ciała, przywrócenie naturalnej siły i poprawnej funkcji wszystkich mięśni, a co za tym idzie poprawienie funkcjonalności stawów i przywrócenie w nich pełnego zakresu ruchu bez dolegliwości bólowych.</p>
                            <p className='about-hero-paragraph_text'>Pracujemy głównie na układzie nerwowym, oddechowym i ruchowym.</p>
                            <div className='about-hero-paragraph_link-container'>
                                <Link to="/oferta" className='about-hero-paragraph_link-link'>OFERTA</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='about-showcase_picture'>
                    <StaticImage 
                        src='../images/about_second.jpg'
                        alt="sprzęt do treningu pilates"
                        className="about-showcase_picture-single"
                    />
                </section>
                <section className='about-hero'>
                    <div className='about-hero-paragraph'>
                        <div className='about-hero-paragraph_name'>
                            <h2 className='about-hero-paragraph_title'>KIM JESTEŚMY</h2>
                        </div>
                        <div className='about-hero-paragraph_content'>
                            <h3 className='about-hero-paragraph_bold-line'>Pilates terapeutyczny Studio to zespół ludzi, którzy w staranny sposób zadbają o Twoje dobre samopoczucie w Twoim ciele, o zminimalizowanie dolegliwości bólowych, o pełne wykorzystanie potencjału Twojego ciała, o siłę, elastyczność i grację.</h3>
                            <p className='about-hero-paragraph_text'>U nas każdy Klient staje się częścią społeczności dbającej o swoje zdrowie psychiczne i fizyczne w świadomy, bezpieczny i efektywny sposób. Z każdym Klientem chcemy nawiązać niezwykłą relację opartą na autentyczności i zaangażowaniu. Jesteśmy wdzięczni naszym Klientom za otwartość, szczerość i zaufanie, dzięki czemu możemy wykonywać najlepszy zawód na świecie.</p>
                            <p className='about-hero-paragraph_text'>Pilates terapeutyczny Studio to także miejsce, w którym w najlepszy sposób zajmiesz się swoim ciałem. Samo nasze podejście, jak i obsługa Klienta realizują się w duchu Client Experience.</p>
                            <p className='about-hero-paragraph_text'>Przestrzeń Studia ma przestronne wnętrza, żeby było Ci wygodnie. Każde z pomieszczeń jest wyposażone w wiele drobiazgów, które zdecydowanie ułatwią Ci pobyt w naszym miejscu i o nic nie będziesz musiał się martwić. Oprócz sal na treningi Pilates czy przestrzeni warsztatowych, oddajemy do Waszej dyspozycji również Salonik, w którym możesz spędzić czas przed zajęciami lub odpocząć po zajęciach. Zadbaj w pełni o czas dla siebie.</p>
                            <h4 className='about-hero-paragraph_bold-line'>Ponieważ działamy bardzo dynamicznie, wszystkie informacje o naszych inicjatywach i aktualnych wydarzeniach znajdziesz na naszym profilu na facebooku.</h4>
                            <div className='about-hero-paragraph_link-container'>
                                <a href="https://www.facebook.com/pilatesterapeutyczny/events" target="_blank" className='about-hero-paragraph_link-link'>WYDARZENIA</a>
                            </div>
                            <div className='about-hero-paragraph_link-container'>
                                <Link to="/zespol" className='about-hero-paragraph_link-link'>POZNAJ ZESPÓŁ</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='about-showcase_picture'>
                    <div className='about-showcase_picture_half'>
                        <StaticImage 
                            src='../images/about_third.jpg'
                            alt="sala treningowa do zajęć pilates"
                            className="about-showcase_picture_half-half"
                        />
                    </div>
                    <div className='about-showcase_picture_half'>
                        <StaticImage 
                            src='../images/about_fourth.jpg'
                            alt="sprzęt treningowy do zajęć pilates"
                            className="about-showcase_picture_half-half"
                        />
                    </div>
                </section>
                <section className='about-hero'>
                    <div className='about-hero-paragraph'>
                        <div className='about-hero-paragraph_name'>
                            <h2 className='about-hero-paragraph_title'>KOMU POLECAMY NASZE ZAJĘCIA?</h2>
                        </div>
                        <div className='about-hero-paragraph_content'>
                            <h3 className='about-hero-paragraph_bold-line'>Pilates terapeutyczny jest optymalną formą zajęć ruchowych dla każdego. Polecamy go zatem :</h3>
                            <p className='about-hero-paragraph_text'>&#x2022; Każdemu – aby poprawił postawę ciała, siłę mięśni, grację i elastyczność ciała</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Osobom z dolegliwościami bólowymi, aby zminimalizowały ból poprzez pracę na układzie nerwowym oraz przywrócenie poprawnych wzorców w układzie ruchu</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Osobom przewlekle chorym, które nie mogą wykonywać innej aktywności fizycznej – aby zaufały swojemu ciału i utrzymywały sprawność</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Osobom o niskiej kondycji fizycznej – aby nabrały świadomości ciała, siły i pewności ruchów</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Osobom w trakcie cyklu psychoterapeutycznego – aby lepiej poznały swoje ciało</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Osobom niewidomym – aby wykorzystały potencjał pracy ze swoim ciałem</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Seniorom – aby zachowali sprawność ruchową</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Kobietom w ciąży – aby bezpiecznie przygotowały swoje ciało do porodu</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Kobietom po porodzie – aby wróciły do pełnej formy i sylwetki po porodzie</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Kobietom w okresie menopauzy – aby lepiej znosiły objawy menopauzy</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Kobietom długo starającym się o dziecko – aby zintegrowały się z kobiecą sferą swojego ciała</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Nastolatkom – aby ich dorosła postawa ciała rozwinęła się w sposób prawidłowy</p>
                            <p className='about-hero-paragraph_text'>&#x2022; Sportowcom i Tancerzom – aby zrównoważyli pracę mięśni całego ciała i odciążyli nadwyrężone stawy</p>
                            <p className='about-hero-paragraph_text'>Pełna lista częstych przypadków Klientów do pobrania poniżej</p>
                            <div className='about-hero-paragraph_download-container'>
                                <a href='/documents/Lista.pdf' download="Lista_przypadków" className='about-hero-paragraph_text about_highlight about_download'>KOMU POMAGAMY &#8628;</a>
                            </div>
                            <div className='about-hero-paragraph_link-container'>
                                <Link to="/zapisy" className='about-hero-paragraph_link-link'>ZAPISY</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='about-showcase_picture'>
                    <div className='about-showcase_picture_half'>
                        <StaticImage 
                            src='../images/about_fifth.jpg'
                            alt="duża sala do zajęć pilates"
                            className="about-showcase_picture_half-half"
                        />
                    </div>
                    <div className='about-showcase_picture_half'>
                        <StaticImage 
                            src='../images/about_sixth.jpg'
                            alt="sprzęt treningowy do zajęć pilates"
                            className="about-showcase_picture_half-half"
                        />
                    </div>
                </section>
                <p className='about-partners'>NASI PARTNERZY</p>
                <section className='about-partners_logos'>
                    <div className='about-partner_logos-containers'><a href="https://klinikafizjoterapii.com/" target="_blank"><img src={klinika_fizjoterapii} alt="logo partnera" className='about-partner_logos-containers-link-unbalanced'></img></a></div>
                    <div className='about-partner_logos-containers'><a href="https://www.clochee.com/" target="_blank"><img src={clochee_logo} alt="logo partnera" className='about-partner_logos-containers-link'></img></a></div>
                    <div className='about-partner_logos-containers'><a href="https://www.facebook.com/p/Agata-M%C5%82ynarska-Osteopata-100057025857155/" target="_blank"><img src={agata_logo} alt="logo partnera" className='about-partner_logos-containers-link'></img></a></div>
                    <div className='about-partner_logos-containers'><a href="https://usmiechzdrowia.pl/" target="_blank"><img src={usmiechZdrowia_logo} alt="logo partnera" className='about-partner_logos-containers-link'></img></a></div>
                    <div className='about-partner_logos-containers'><a href="https://www.dragonflychair.com/krzeslo-ortopedyczne/" target="_blank"><img src={dragonfly_logo} alt="logo partnera" className='about-partner_logos-containers-link'></img></a></div>
                    <div className='about-partner_logos-containers'><a href="https://ayakomasaz.pl/" target="_blank"><img src={ayako_logo} alt="logo partnera" className='about-partner_logos-containers-link'></img></a></div>
                    <div className='about-partner_logos-containers'><a href="https://www.facebook.com/filozofiastopy/" target="_blank"><img src={filozofiaStopy_logo} alt="logo partnera" className='about-partner_logos-containers-link-tall'></img></a></div>
                </section>
            </section>
        </Layout>
    )
}

export default AboutPage;

//                     <a href="https://www.clochee.com/" target="_blank" className='about-partner_logo-picture-wide'><img src={clochee_logo} alt="logo partnera" className='about-partner_logo-picture-wide'></img></a>
//                     <a href="https://www.facebook.com/p/Agata-M%C5%82ynarska-Osteopata-100057025857155/" target="_blank" className='about-partner_logo-picture-wide'><img src={agata_logo} alt="logo partnera" className='about-partner_logo-picture-wide'></img></a>
//                     <a href="https://usmiechzdrowia.pl/" target="_blank" className='about-partner_logo-picture-wide'><img src={usmiechZdrowia_logo} alt="logo partnera" className='about-partner_logo-picture-wide'></img></a>
//                     <a href="https://www.dragonflychair.com/krzeslo-ortopedyczne/" target="_blank" className='about-partner_logo-picture-wide'><img src={dragonfly_logo} alt="logo partnera" className='about-partner_logo-picture-wide'></img></a>
//                     <a href="https://ayakomasaz.pl/" target="_blank" className='about-partner_logo-picture-wide'><img src={ayako_logo} alt="logo partnera" className='about-partner_logo-picture-wide'></img></a>
//                     <a href="https://www.facebook.com/filozofiastopy/" target="_blank" className='about-partner_logo-picture-tall'><img src={filozofiaStopy_logo} alt="logo partnera" className='about-partner_logo-picture-tall'></img></a>